import Api from "apiInstance";
import { toast } from "react-toastify";

export const fleetGet = async () => {
  try {
    const response = await Api({
      method: "get",
      url: `/fleet/`,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
export const fleetGetByRiderMobile = async ({ mobile }) => {
  try {
    const response = await Api({
      method: "get",
      url: `/fleet/rider/${mobile}`,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
export const fleetAdd = async (body) => {
  try {
    const response = await Api({
      method: "post",
      url: `/fleet/`,
      data: body,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
export const fleetTypeGet = async () => {
  try {
    const response = await Api({
      method: "get",
      url: `/fleet/type`,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
export const fleetTypeAdd = async (body) => {
  try {
    const response = await Api({
      method: "post",
      url: `/fleet/type`,
      data: body,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
export const riderAssignToFleet = async (body) => {
  try {
    const response = await Api({
      method: "post",
      url: `/fleet/rider`,
      data: body,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
export const ridersGet = async ({ page }) => {
  try {
    const response = await Api({
      method: "get",
      url: `/riders?page=${page}`,
    });
    return { data: response?.data?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const filterableRidersGet = async ({
  page,
  approved,
  blacklisted,
  available,
  active,
  activated,
}) => {
  try {
    const response = await Api({
      method: "get",
      url: `/riders/?page=${page}&limit=100${
        approved === undefined ? "" : `&approved=${approved}`
      }${blacklisted === undefined ? "" : `&blacklisted=${blacklisted}`}${
        available === undefined ? "" : `&available=${available}`
      }${active === undefined ? "" : `&active=${active}`}${
        activated === undefined ? "" : `&activated=${activated}`
      }`,
    });
    return {
      data: response?.data?.data?.riders,
      status: response?.status,
      currentPage: Number(response?.data?.data?.page),
      total: response?.data?.data?.total,
      limit: response?.data?.data?.limit,
    };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const ridersGetByTeamId = async ({ page, teamId }) => {
  try {
    const response = await Api({
      method: "get",
      url: `/riders/team/${teamId}?page=${page}`,
    });
    return { data: response?.data?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const riderRegister = async (body) => {
  try {
    const response = await Api({
      method: "post",
      url: `/auth/register`,
      data: body,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const riderTeamUpdate = async ({ id, body }) => {
  try {
    const response = await Api({
      method: "put",
      url: `/riders/${id}/updateTeam`,
      data: body,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const riderAssignToClient = async ({ riderId, clientId }) => {
  try {
    const response = await Api({
      method: "put",
      url: `/riders/${riderId}/assignToClient/${clientId}`,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const multipleRidersTeamUpdate = async (body) => {
  try {
    const response = await Api({
      method: "put",
      url: `/riders/updateTeam`,
      data: body,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const fleetGetByRegNumber = async ({ regNumber }) => {
  try {
    const response = await Api({
      method: "get",
      url: `/fleet/regNumber/${regNumber}`,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const riderApprove = async ({ authId }) => {
  try {
    const response = await Api({
      method: "put",
      url: `/riders/${authId}/approve`,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
export const riderDeactivate = async ({ authId }) => {
  try {
    const response = await Api({
      method: "put",
      url: `/riders/${authId}/deactivate`,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
export const riderBlacklist = async ({ authId }) => {
  try {
    const response = await Api({
      method: "put",
      url: `/riders/${authId}/blacklist`,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const fleetDeactivate = async ({ regNumber }) => {
  try {
    const response = await Api({
      method: "put",
      url: `/fleet/regNumber/${regNumber}/deactivate`,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const fleetActivate = async ({ regNumber }) => {
  try {
    const response = await Api({
      method: "put",
      url: `/fleet/regNumber/${regNumber}/activate`,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const availableRidersForDeliveryGet = async ({
  merchantId,
  locLatitude,
  locLongitude,
}) => {
  try {
    const response = await Api({
      method: "get",
      url: `/riders/availableForDelivery?${
        locLatitude === undefined ? "" : `locLatitude=${locLatitude}`
      }${locLongitude === undefined ? "" : `&locLongitude=${locLongitude}`}${
        merchantId === undefined ? "" : `&merchantId=${merchantId}`
      }`,
    });
    return {
      data: response?.data?.data?.riders,
      status: response?.status,
    };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
