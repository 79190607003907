import Api from "apiInstance";
import { toast } from "react-toastify";

export const clientsGet = async () => {
  try {
    const response = await Api({
      method: "get",
      url: `/client/`,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
export const clientsApprove = async (body) => {
  try {
    const response = await Api({
      method: "put",
      url: `/client/approve`,
      data: body,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    // toast.error(error?.response?.data?.message + "!");
  }
};
export const clientsOpeningHoursSet = async (body) => {
  try {
    const response = await Api({
      method: "put",
      url: `/user/setOpeningHours`,
      data: body,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const merchantStoreGet = async ({ id, page }) => {
  try {
    const response = await Api({
      method: "get",
      url: `/client/${id}/stores?page=${page}`,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const merchantUsersGet = async ({ id }) => {
  try {
    const response = await Api({
      method: "get",
      url: `/client/${id}/users`,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
export const merchantUsersCreate = async ({ id, body }) => {
  try {
    const response = await Api({
      method: "post",
      url: `/client/${id}/user`,
      data: body,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const clientTeamUpdate = async ({ id, body }) => {
  try {
    const response = await Api({
      method: "put",
      url: `/client/${id}/updateTeam`,
      data: body,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const clientGetByTeamId = async ({ id }) => {
  try {
    const response = await Api({
      method: "get",
      url: `/client/team/${id}`,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

// export const merchantApprovedRateGet = async ({
//   id,
//   page,
//   active,
//   approved,
//   merchantOutletId,
// }) => {
//   try {
//     const response = await Api({
//       method: "get",
//       url: `/rate/approved/merchant/${id}?page=${page}&limit=30${
//         active === undefined ? "" : `&active=${active}`
//       }${approved === undefined ? "" : `&approved=${approved}`}${
//         merchantOutletId === undefined
//           ? ""
//           : `&merchantOutletId=${merchantOutletId}`
//       }`,
//     });
//     return {
//       data: response?.data?.data?.approvedRates,
//       status: response?.status,
//       currentPage: Number(response?.data?.data?.page),
//       total: response?.data?.data?.total,
//       limit: response?.data?.data?.limit,
//     };
//   } catch (error) {
//     toast.error(error?.response?.data?.message + "!");
//   }
// };

export const adminApprovedRateGet = async ({
  page,
  active,
  approved,
  merchantId,
  merchantOutletId,
}) => {
  try {
    const response = await Api({
      method: "get",
      url: `/rate/approved?page=${page}&limit=30${
        active === undefined ? "" : `&active=${active}`
      }${approved === undefined ? "" : `&approved=${approved}`}${
        merchantId === undefined ? "" : `&merchantId=${merchantId}`
      }${
        merchantOutletId === undefined
          ? ""
          : `&merchantOutletId=${merchantOutletId}`
      }`,
    });
    return {
      data: response?.data?.data?.rates,
      status: response?.status,
      currentPage: Number(response?.data?.data?.page),
      limit: response?.data?.data?.limit,
    };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const approveRateAdd = async (body) => {
  try {
    const response = await Api({
      method: "post",
      url: `/rate/approved`,
      data: body,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const approveRateUpdate = async ({ id, body }) => {
  try {
    const response = await Api({
      method: "put",
      url: `/rate/approved/${id}`,
      data: body,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const outletStoreGet = async ({ id }) => {
  try {
    const response = await Api({
      method: "get",
      url: `/client/${id}/store`,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
