import Api from "apiInstance";
import { toast } from "react-toastify";

export const adminsGet = async () => {
  try {
    const response = await Api({
      method: "get",
      url: "/admin/admins",
    });
    return {
      data: response?.data?.data?.admins,
      status: response?.status,
      currentPage: Number(response?.data?.data?.page),
      total: response?.data?.data?.total,
      limit: response?.data?.data?.limit,
    };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const adminGetById = async ({ id }) => {
  try {
    const response = await Api({
      method: "get",
      url: `/admin/${id}`,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const adminEdit = async (body) => {
  try {
    const response = await Api({
      method: "put",
      url: `/admin/`,
      data: body,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const adminDelete = async ({ id }) => {
  try {
    const response = await Api({
      method: "delete",
      url: `/admin/${id}`,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const adminRegister = async (body) => {
  try {
    const response = await Api({
      method: "post",
      url: `/auth/register/admin`,
      data: body,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.data?.message + "!");
  }
};
