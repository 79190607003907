import Api from "apiInstance";
import { toast } from "react-toastify";

export const teamsGet = async ({ page }) => {
  try {
    const response = await Api({
      method: "get",
      url: `/team/teams?page=${page}`,
    });
    return {
      data: response?.data,
      status: response?.status,
      currentPage: page,
    };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const teamGetByID = async ({ id }) => {
  try {
    const response = await Api({
      method: "get",
      url: `/team/${id}`,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const teamsCreate = async (body) => {
  try {
    const response = await Api({
      method: "post",
      url: "/team/",
      data: body,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const RiderToTeamAssign = async ({ teamId, riders }) => {
  try {
    const response = await Api({
      method: "post",
      url: `/team/${teamId}/addRider`,
      data: riders,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const teamGetRiders = async ({ id }) => {
  try {
    const response = await Api({
      method: "get",
      url: `/team/${id}/getRiders`,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const merchantAddToTeam = async ({ id, merchant }) => {
  try {
    const response = await Api({
      method: "post",
      url: `/team/${id}/addMerchants`,
      data: merchant,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const merchantTeamGetById = async ({ id }) => {
  try {
    const response = await Api({
      method: "get",
      url: `/team/merchant/${id}?page=1&limit=30`,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const adminAddToTeam = async ({ id, admin }) => {
  try {
    const response = await Api({
      method: "put",
      url: `/team/${id}/addUser`,
      data: admin,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const adminRemoveToTeam = async ({ teamId, adminId }) => {
  try {
    const response = await Api({
      method: "put",
      url: `/team/${teamId}/removeUser/${adminId}`,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
